/*-----------This is Masonry CSS-----------*/

.my-masonry-grid {
    display: -webkit-box;
    /* Not needed if autoprefixing */
    display: -ms-flexbox;
    /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px;
    /* gutter size offset */
    width: auto;
}

.my-masonry-grid_column {
    padding-left: 30px;
    /* gutter size */
    background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column>div {
    /* change div to reference your elements you put in <Masonry> */
    /* background: grey; */
    margin-bottom: 30px;
}


/* THIS IS THE CSS FOR THE SEARCH BUTTON */
.search-box {
    width: fit-content;
    height: fit-content;
    position: relative;
}

.input-search {
    height: 25px;
    width: 10px;
    border-style: none;
    padding: 5px;
    font-size: 15px;
    letter-spacing: 2px;
    outline: none;
    border-radius: 25px;
    transition: all .5s ease-in-out;
    background-color: #8266ac;
    padding-right: 69px;
    color: #fff;
}

.input-search::placeholder {
    color: rgba(255, 255, 255, .5);
    font-size: 12px;
    letter-spacing: 2px;
    font-weight: 50;
}

.btn-search {
    width: 40px;
    height: 27px;
    border-style: none;
    font-size: 15px;
    /* font-weight: bold; */
    outline: none;
    cursor: pointer;
    border-radius: 50%;
    position: absolute;
    right: 0px;
    color: #ffffff;
    background-color: transparent;
    pointer-events: painted;
}

.btn-search:focus~.input-search {
    width: 300px;
    border-radius: 0px;
    background-color: transparent;
    border-bottom: 1px solid rgba(255, 255, 255, .5);
    transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}

.input-search:focus {
    width: 320px;
    border-radius: 0px;
    background-color: transparent;
    border-bottom: 1px solid rgba(255, 255, 255, .5);
    transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}


/*-----------Blog Banner-----------*/
.bannerContainer {
    position: relative;
    text-align: center;
    color: white;
}

.top-right-header {
    position: absolute;
    top: 8px;
    right: 16px;
}

.top-left-header {
    position: absolute;
    top: 8px;
    left: 16px;
    color: white;
    mix-blend-mode: difference;

}